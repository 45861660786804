
import { IonCol,IonContent, IonGrid, IonPage, IonRow } from "@ionic/vue";
import { defineComponent } from "vue";

import EmployeeSignUp from "@/components/authentication/EmployeeSignUp.vue";

export default defineComponent({
  name: "Authentication",
  components: {
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonContent,
    EmployeeSignUp,
  },
  props: {
    email: String,
  },
});
